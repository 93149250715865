import { Button, Flex, Heading } from '@pancakeswap/uikit'
import { useWeb3React } from '@pancakeswap/wagmi'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { NextLinkFromReactRouter } from 'components/NextLink'
import { useTranslation } from '@pancakeswap/localization'
import Image from 'next/image'
import styled, { keyframes } from 'styled-components'
import bunnyImage from '../../../../public/images/home/lunar-bunny/astronaut-bunny.png'
import CompositeImage, { CompositeImageProps } from './CompositeImage'
import { SlideSvgDark, SlideSvgLight } from './SlideSvg'

const flyingAnim = () => keyframes`
  from {
    transform: translate(0,  0px);
  }
  50% {
    transform: translate(-5px, -5px);
  }
  to {
    transform: translate(0, 0px);
  }
`

const fading = () => keyframes`
  from {
    opacity: 0.9;
  }
  50% {
    opacity: 0.1;
  }
  to {
    opacity: 0.9;
  }
`

const BgWrapper = styled.div`
  z-index: -1;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0px;
  left: 0px;
`

const InnerWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: -3px;
`

const BunnyWrapper = styled.div`
  width: 100%;
  animation: ${flyingAnim} 3.5s ease-in-out infinite;
  will-change: transform;
  > span {
    overflow: visible !important; // make sure the next-image pre-build blur image not be cropped
  }
`

const StarsWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  & :nth-child(2) {
    animation: ${fading} 2s ease-in-out infinite;
    animation-delay: 1s;
  }

  & :nth-child(3) {
    animation: ${fading} 5s ease-in-out infinite;
    animation-delay: 0.66s;
  }

  & :nth-child(4) {
    animation: ${fading} 2.5s ease-in-out infinite;
    animation-delay: 0.33s;
  }
`

const starsImage: CompositeImageProps = {
  path: '/images/home/lunar-bunny/',
  attributes: [
    { src: 'star-l', alt: '3D Star' },
    { src: 'star-r', alt: '3D Star' },
    { src: 'star-top-r', alt: '3D Star' },
  ],
}

const Hero = () => {
  const { t } = useTranslation()
  const { account } = useWeb3React()

  return (
    <>
      <style jsx global>
        {`
          .slide-svg-dark {
            display: none;
          }
          .slide-svg-light {
            display: block;
          }
          [data-theme='dark'] .slide-svg-dark {
            display: block;
          }
          [data-theme='dark'] .slide-svg-light {
            display: none;
          }
        `}
      </style>
      <BgWrapper>
        <InnerWrapper>
          <SlideSvgDark className="slide-svg-dark" width="100%" />
          <SlideSvgLight className="slide-svg-dark" width="100%" />
        </InnerWrapper>
      </BgWrapper>
      <Flex
        position="relative"
        flexDirection={['column-reverse', null, null, 'row']}
        alignItems={['flex-end', null, null, 'center']}
        justifyContent="center"
        mt={[account ? '280px' : '50px', null, 0]}
        id="homepage-hero"
      >
        <Flex flex="1" flexDirection="column">
		<Heading scale="xxl" mb="24px">
		<span style={{ color: '#b327b7' }}>{t('GROK')}</span>
		<span style={{ color: '#48cadf' }}>{t('XL')}</span>
		</Heading>
          <Heading scale="md" mb="24px">
            {t('Explore GROKXL on BscScan – the premier decentralized exchange on the Binance Smart Chain. Discover a seamless and secure trading experience with a wide range of tokens. Check transaction details, analytics, and more. Join the GROKXL community today!')}
          </Heading>
		  		  		    <Flex>
		    {/* Twitter icon */}
  <a href="https://twitter.com/GrokXLai" target="_blank" rel="noopener noreferrer">
    <img src="./twitter.png" alt="Twitter" style={{ width: '42px', height: '42px', marginRight: '8px' }} />
  </a>

  {/* Telegram icon */}
  <a href="https://t.me/grokxl" target="_blank" rel="noopener noreferrer">
    <img src="./telegram.png" alt="Telegram" style={{ width: '42px', height: '42px', marginRight: '8px' }} />
  </a>
</Flex>
          <Flex>

            {!account && <ConnectWalletButton mr="8px" />}
            <NextLinkFromReactRouter to="/Whitepaper.pdf">
              <Button variant={!account ? 'secondary' : 'primary'}>{t('Whitepaper')}</Button>
            </NextLinkFromReactRouter>
			<a href="mailto:info@grokxl.com">
			<Button variant={!account ? 'secondary' : 'primary'}>{t('Contact')}</Button>
			</a>
          </Flex>
	    </Flex>
        <Flex
          height={['192px', null, null, '100%']}
          width={['192px', null, null, '100%']}
          flex={[null, null, null, '1']}
          mb={['24px', null, null, '0']}
          position="relative"
        >
          <BunnyWrapper>
            <Image src={bunnyImage} priority placeholder="blur" alt={t('Lunar bunny')} />
          </BunnyWrapper>
          <StarsWrapper>
            <CompositeImage {...starsImage} />
          </StarsWrapper>
        </Flex>
      </Flex>

    </>
  )
}

export default Hero
