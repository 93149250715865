import { TranslateFunction } from '@pancakeswap/localization'
import { SalesSectionProps } from '.'

export const swapSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: t('Ecosystem Development.'),
  bodyText: t('Allocate tokens for the development and growth of the ecosystem surrounding the GROKXL token. This may include partnerships, marketing, and community development.'),
  reverse: false,
  primaryButton: {
    to: '/swap',
    text: t('Trade Now'),
    external: false,
  },
  secondaryButton: {
    to: 'https://grookxl.com/',
    text: t('Learn'),
    external: true,
  },
  images: {
    path: '/images/home/trade/',
    attributes: [
      { src: 'BNB', alt: t('BNB token') },
      { src: 'BTC', alt: t('BTC token') },
      { src: 'CAKE', alt: t('CAKE token') },
    ],
  },
})

export const earnSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: t('Security and Audits'),
  bodyText: t('The smart contracts underlying the GROKXL token will undergo rigorous security audits to identify and address vulnerabilities. Audit reports will be shared with the community to ensure transparency and trust.'),
  reverse: true,
  primaryButton: {
    to: '/info',
    text: t('Explore'),
    external: false,
  },
  secondaryButton: {
    to: 'https://grookxl.com/',
    text: t('Learn'),
    external: true,
  },
  images: {
    path: '/images/home/earn/',
    attributes: [
      { src: 'folder', alt: t('Folder with cake token') },
    ],
  },
})

export const cakeSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: t('Tokenomics'),
  bodyText: t(
    'The GROKXL token serves multiple purposes within the ecosystem, including:\n' +
      'Governance rights for decision-making within the community,\n' +
      'Staking for network security and participation in consensus,\n' +
      'Access to special features, services, or discounts within the GROKXL ecosystem,\n' +
      'Incentives for active community members through reward programs.\n'
  ),
  
  reverse: false,
  primaryButton: {
    to: '/swap',
    text: t('Buy GROKXL'),
    external: false,
  },
  secondaryButton: {
    to: 'https://grookxl.com/',
    text: t('Learn'),
    external: true,
  },

  images: {
    path: '/images/home/cake/',
    attributes: [

      { src: 'coin', alt: t('CAKE token') },
    ],
  },
})
